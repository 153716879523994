import React, { FC } from "react";
import { Elements, StripeProvider } from 'react-stripe-elements';
import Layout from "../components/layout";
import SEO from "../components/seo";
import AddPayment from "../components/payment";

const IndexPage: FC = () => (
  <Layout header requireAuth>
    <SEO title="Accueil" />
    {typeof window !== 'undefined' ?
      <StripeProvider apiKey={`${process.env.GATSBY_STRIPE_API_KEY_PUBLIC}`}>
        <Elements>
          <AddPayment />
        </Elements>
      </StripeProvider>
    : null}
  </Layout>
);

export default IndexPage;
